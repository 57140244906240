export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://www.gcode.com.do/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/gabrielrodz3o',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://twitter.com/gabrielrodz3o',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/gabriel-rodriguez-02226121a/',
	}

];
