// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 15,
		title: 'Help Desk system',
		category: 'Web Application',
		img: require('@/assets/images/ticket1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Ticket System',
			category: 'Web Application',
			singleProjectDate: 'Dec 12, 2023',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/ticket0.png'),
			},
			{
				img: require('@/assets/images/ticket5.png'),
			},
			{
				img: require('@/assets/images/ticket1.png'),
			},
			{
				img: require('@/assets/images/ticket2.png'),
			},
			{
				img: require('@/assets/images/ticket3.png'),
			},
			{
				img: require('@/assets/images/ticket4.png'),
			}
	
		],
		projectInfo: {
			clientHeading: 'Banca la suerte',
			companyInfos: [
				{
					id: 1,
					title: 'GM',
					details: 'Company Ltd',
				},
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'Nuxt 3',
						'HTML',
						'CSS',
						'Typescript',
						'Vuetify',
						'NestJS',
						'Postgresql'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This project is track tickets'
				},
		
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gym1.png'),
				},
			
			],
		}
	},
	{
		id: 16,
		title: 'AN Marketing',
		category: 'Web Application',
		img: require('@/assets/images/an1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'AN Marketing',
			category: 'Web Application',
			singleProjectDate: 'Nov 6, 2023',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/an1.png'),
			},
			{
				img: require('@/assets/images/an2.png'),
			},
			{
				img: require('@/assets/images/an3.png'),
			},
			{
				img: require('@/assets/images/an4.png'),
			},
			{
				img: require('@/assets/images/an5.png'),
			}
	
		],
		projectInfo: {
			clientHeading: 'ANAGENCIA',
			companyInfos: [
				{
					id: 1,
					title: 'GM',
					details: 'Company Ltd',
				},
				{
					id: 3,
					title: 'Website',
					details: 'anagencia.com',
				}
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'Nuxt 3',
						'HTML',
						'CSS',
						'Typescript',
						'Vuetify',
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This project is info page'
				},
		
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/rest1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/acis1.png'),
				},
			
			],
		}
	},
	{
		id: 0,
		title: 'Restaurant system',
		category: 'Web Application',
		img: require('@/assets/images/rest4.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Restaurant System',
			category: 'Web Application',
			singleProjectDate: 'Jul 20, 2023',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/rest_dash.png'),
			},
			{
				img: require('@/assets/images/rest4.png'),
			},
			{
				img: require('@/assets/images/rest1.png'),
			},
			{
				img: require('@/assets/images/rest_products.png'),
			},
			{
				img: require('@/assets/images/rest2.png'),
			},
			{
				img: require('@/assets/images/rest3.png'),
			},
			{
				img: require('@/assets/images/rest8.png'),
			},
		],
		projectInfo: {
			clientHeading: 'Gcode',
			companyInfos: [
				{
					id: 1,
					title: 'GM',
					details: 'Company Ltd',
				},
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'Nuxt 3',
						'HTML',
						'CSS',
						'Typescript',
					
						'Asp.net core',
						
						'Vuetify',
						'NodeJs',
						'Postgresql'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This project has an API that prints orders per production center on a thermal printer'
				},
				{
					id: 2,
					details:'Can join and split tables'
				},
				{
					id: 3,
					details:'you can join and split accountsM,multiple orders'
				},
				{
					id: 4,
					details:'multiple payments'
				},
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gym1.png'),
				},
				
			],
		}
	},
	{
		id: 11,
		title: 'Point of sales system',
		category: 'Web Application',
		img: require('@/assets/images/point1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Point sales System',
			category: 'Web Application',
			singleProjectDate: 'may 10, 2023',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/point1.png'),
			},
			{
				img: require('@/assets/images/point2.png'),
			},
		
			{
				img: require('@/assets/images/point4.png'),
			},
			{
				img: require('@/assets/images/point5.png'),
			},
		],
		projectInfo: {
			clientHeading: 'Grupo M',
			companyInfos: [
				{
					id: 1,
					title: 'GM',
					details: 'Company Ltd',
				},
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'Vue 3',
						'HTML',
						'CSS',
						'Typescript',
							
						'Vuetify',
						'NodeJs',
						'Postgresql'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'System pos'
				},
		
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/rest1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/acis1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gym1.png'),
				},
				
			],
		}
	},
	{
		id: 12,
		title: 'Acis online',
		category: 'Web Application',
		img: require('@/assets/images/acis1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Acis online',
			category: 'Web Application',
			singleProjectDate: 'jul 1, 2023',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/acis1.png'),
			},
			{
				img: require('@/assets/images/acis2.png'),
			},
		
			{
				img: require('@/assets/images/acis3.png'),
			},
			{
				img: require('@/assets/images/acis4.png'),
			},
			{
				img: require('@/assets/images/acis5.png'),
			},
			{
				img: require('@/assets/images/acis6.png'),
			},
		],
		projectInfo: {
			clientHeading: 'ACIS',
			companyInfos: [
				{
					id: 1,
					title: 'ACIS',
					details: 'Company Ltd',
				},
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'Nuxt 3',
						'HTML',
						'CSS',
						'Typescript',
						'Supabase',
							
						'Vuetify',
		
						'Postgresql'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'System of course online'
				},
		
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/point1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/rest1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gym1.png'),
				},
			
			],
		}
	},
	{
		id: 1,
		title: 'Human Resource System',
		category: 'Web Application',
		img: require('@/assets/images/human1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Human Resource System',
			category: 'Web Application',
			singleProjectDate: 'Jul 26, 2021',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/human1.png'),
			},
			{
				img: require('@/assets/images/human2.png'),
			},
			{
				img: require('@/assets/images/human3.png'),
			},
		],
		projectInfo: {
			clientHeading: 'Grupo M',
			companyInfos: [
				{
					id: 1,
					title: 'GM',
					details: 'Company Ltd',
				},
		
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify',
						'NodeJs',
						'Postgresql'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This a project of Human resource'
				},
				{
					id: 2,
					details:'With Payroll system'
				},
				{
					id: 3,
					details:'Saving Plans'
				},
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gym1.png'),
				},
			
			],
		}
	},
	{
		id: 2,
		title: 'Finnace System',
		category: 'Web Application',
		img: require('@/assets/images/finan1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Finnace System',
			category: 'Web Application',
			singleProjectDate: 'Ago 22, 22',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/finan1.png'),
	
			},
			{
				img: require('@/assets/images/finan2.png'),
	
			},
			{
				img: require('@/assets/images/finan3.png'),
	
			},
		],
		projectInfo: {
			clientHeading: 'GPM Client',
			companyInfos: [
				{
					id: 1,
					title: 'CODEVI',
					details: 'Company Ltd',
				},
				
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify',
						'Posgresql',
						'NodeJs'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This a project Finnace'
				}
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/human1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/gym1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gif1.png'),
				},
			
			],
		}
	},
	{
		id: 3,
	
		title: 'Nene Rent car',
		category: 'Web Application',
		img: require('@/assets/images/nene1.png'),

		singleProjectHeader: {

			singleProjectTitle: 'System rent car',
			category: 'Web Application',
			singleProjectDate: 'Oct 8, 2022',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/nene1.png'),
				
				
			},
			{
				img: require('@/assets/images/nene2.png'),
			},
			{
				img: require('@/assets/images/nene3.png'),
			}
		],
		projectInfo: {
			clientHeading: 'Company',
			companyInfos: [
				{
					id: 1,
					title: 'Nene rent car',
					details: 'Company',
				},
				{
					id: 3,
					title: 'Website',
					details: 'www.nenerentcar.com',
				}
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:
						'This is system of rent car'
				}
			],
			socialSharingsHeading: 'Share This',
			
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/human1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gif1.png'),
				},
			
			],
		}

	},
	{
		id: 4,
	
		title: 'Gold Rent Car',
		category: 'Web Application',
		img: require('@/assets/images/gold1.png'),

		singleProjectHeader: {

			singleProjectTitle: 'System Rent Car',
			category: 'Web Application',
			singleProjectDate: 'Dec , 2022',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/gold1.png'),
				
				
			},
			{
				img: require('@/assets/images/gold2.png'),
			},
			{
				img: require('@/assets/images/gold3.png'),
			}
		],
		projectInfo: {
			clientHeading: 'Company',
			companyInfos: [
				{
					id: 1,
					title: 'Gold rent car',
					details: 'Company',
				},
				{
					id: 2,
					title: 'Services',
					details: 'UI Design & Frontend Development',
				},
			
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:
						'This is system of rent car'
				}
			],
			socialSharingsHeading: 'Share This',
			
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/human1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gif1.png'),
				},
			
			],
		}

	},
	{
		id: 5,
	
		title: 'Dentist System',
		category: 'Web Application',
		img: require('@/assets/images/dentist1.png'),

		singleProjectHeader: {

			singleProjectTitle: 'System Dentist',
			category: 'Web Application',
			singleProjectDate: 'Apr 1, 2021',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/dentist1.png'),
				
				
			},
			{
				img: require('@/assets/images/dentis2.png'),
			},
			{
				img: require('@/assets/images/dentist3.png'),
			}
		],
		projectInfo: {
			clientHeading: 'Company',
			companyInfos: [
				{
					id: 1,
					title: 'Name',
					details: 'Company',
				},
				{
					id: 2,
					title: 'Services',
					details: 'UI Design & Frontend Development',
				},
				{
					id: 3,
					title: 'Website',
					details: '',
				},
				{
					id: 4,
					title: 'Phone',
					details: '000 000 000',
				},
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:
						'This is system of dentis managment'
				}
			],
			socialSharingsHeading: 'Share This',
			
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/human1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/finan1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/gif1.png'),
				},
			
			],
		}

	},
	{
		id: 6,
		title: 'System gift box',
		category: 'Web Application',
		img: require('@/assets/images/gif1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'System  of gift box',
			category: 'Web Application',
			singleProjectDate: 'May 12, 2022',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/gif1.png'),
			
			},
			{
				img: require('@/assets/images/gif2.png'),
			
			},
			{
				img: require('@/assets/images/gif3.png'),
			
			}
		],
		projectInfo: {
			clientHeading: 'Gift services',
			companyInfos: [
			
				{
					id: 2,
					title: 'Services',
					details: 'UI Design & Frontend Development',
				},
				{
					id: 3,
					title: 'Website',
					details: 'https://giftplusme.com',
				}
			
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Vue.js',
						'Vuetify'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This is a project for gift services box'
				},
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
			
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/gym1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/human1.png'),
				},
			
			],
		}
	},
	{
		id: 7,
		title: 'Gym System',
		category: 'Web Application',
		img: require('@/assets/images/gym1.png'),
		singleProjectHeader: {

			singleProjectTitle: 'Gym System',
			category: 'Web Application',
			singleProjectDate: 'Feb 1, 2021',
			singleProjectTag: 'UI / Frontend',
		},
		projectImages: [
			{
				img: require('@/assets/images/gym1.png'),
			
			},
			{
				img: require('@/assets/images/gym2.png'),
			
			},
			{
				img: require('@/assets/images/gym3.png'),
			
			},
		],
		projectInfo: {
			clientHeading: 'Grupo M',
			companyInfos: [
				{
					id: 1,
					title: 'Codevi',
					details: 'Company ',
				},
			
				{
					id: 3,
					title: 'Website',
					details: '',
				},
			
			],
			objectivesHeading: 'Objective',
			objectivesDetails:
				'',
			technologies: [
				{
					title: 'Tools & Technologies',
					techs: [
						'HTML',
						'CSS',
						'JavaScript',
						'Php',
						'Boostrap'
					],
				},
			],
			projectDetailsHeading: 'Challenge',
			projectDetails: [
				{
					id: 1,
					details:'This a project for Members club '
				},
			
			],
			socialSharingsHeading: 'Share This',
			socialSharings: [
		
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Related Projects',
			relatedProjects: [
				{
					id: 1,
					title: 'Mobile UI',
					img: require('@/assets/images/dentist1.png'),
				},
				{
					id: 2,
					title: 'Web Application',
					img: require('@/assets/images/human1.png'),
				},
				{
					id: 3,
					title: 'UI Design',
					img: require('@/assets/images/finan1.png'),
				},
			
			],
		}
	},


];

export default projects;
